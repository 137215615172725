<template>
  <div class="potential">
    <rxNavBar :title="navTitle"></rxNavBar>
    <!-- <p class="application_time">申请时间：<span>{{$route.query.reportingTime}}</span></p> -->
    <p class="subtitle">基本信息</p>
    <div class="panel">
      <!-- <div class="state">{{$route.query.approvalStatusStr}}</div> -->
      <van-row class="info">
        <van-col span="12">
          <p>
            状态: <span>{{ baseForm.approvalStatusStr }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            姓名: <span>{{ baseForm.name }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            手机号: <span>{{ baseForm.phoneNum }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            性别: <span>{{ baseForm.gender }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            试岗部门：<span>{{ baseForm.departmentName }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            职务：<span>{{ baseForm.dutyName }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            推荐人：<span>{{ baseForm.staffName }}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            上级：<span>{{ baseForm.seniorStaffName }}</span>
          </p>
        </van-col>
        <van-col span="24">
          <p>
            证件类型：<span>{{ baseForm.staffCertificateType }}</span>
          </p>
        </van-col>
        <van-col span="24">
          <p>
            证件号: <span>{{ baseForm.idCard }}</span>
          </p>
        </van-col>
      </van-row>
    </div>
    <p class="subtitle">审批信息</p>
    <div>
      <div class="panel radio_body withMargin">
        <p style="marginbottom: 15px">
          <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
          审核结果
        </p>
        <div class="choose">
          <div
            :class="isPass == 1 ? 'choose_btn pass' : 'choose_btn'"
            @click="multipleChoice(1)"
          >
            通过
          </div>
          <div class="line"></div>
          <div
            :class="isPass == 0 ? 'choose_btn reject' : 'choose_btn'"
            @click="multipleChoice(0)"
          >
            驳回
          </div>
        </div>
      </div>
      <div
        id="cleanId"
        v-show="show"
        class="part-inputpart-row"
        @click="isCleanTypeShow = !isCleanTypeShow"
      >
        <span :class="!selectCleanType.dictionaryTitle ? 'notdot' : 'isdot'">
        </span>
        <span class="part-inputpart-row-header">原因</span>
        <span class="divide">|</span>
        <span
          class="part-inputpart-row-graytext"
          :class="
            selectCleanType.dictionaryTitle
              ? 'part-inputpart-row-normaltext'
              : ''
          "
          >{{ selectCleanType.dictionaryTitle }}</span
        >
        <img
          class="part-inputpart-row-right"
          :class="
            isCleanTypeShow
              ? 'part-inputpart-row-right-upArrow'
              : 'part-inputpart-row-right-downArrow'
          "
          src="../../../assets/images/triangle.png"
        />
      </div>

      <div v-show="!show">
        <div>
            <div
          class="part-inputpart-row"
          id="expectedDatePanel"
          @click="isExpectedDateShow = true"
        >
        <span
            :class="
              !formValidate.entryTime ? 'notdot' : 'isdot'
            "
          >
          </span>
          <span class="part-inputpart-row-header"
            >试岗日期</span
          >
          <span class="divide">|</span>
          <span
            class="part-inputpart-row-graytext"
            :class="formValidate.entryTime ? 'part-inputpart-row-redtext' : ''"
          >
            {{ formValidate.entryTime | formatDateTime }}
          </span>
          <img
            class="part-inputpart-row-right part-inputpart-row-right-timepick"
            src="../../../assets/images/timepick.png"
          />
        </div>
        </div>

        <div class="part-inputpart-row">
            <div>
          <span
            :class="
              !formValidate.registeredResidenceAddress ? 'notdot' : 'isdot'
            "
          >
          </span>
          <span class="part-inputpart-row-header">户籍地址</span>
          <span class="divide">|</span>
          <input
            v-model="formValidate.registeredResidenceAddress"
            placeholder="请输入户籍地址"
          />
        </div>
      </div>

      <div
        class="part-inputpart-row"
        @click="isDutyLevelShow = !isDutyLevelShow"
      >
        <span :class="!formValidate.dutyLevel_id ? 'notdot' : 'isdot'">
        </span>
        <span class="part-inputpart-row-header">级别</span>
        <span class="divide">|</span>
        <span
          class="part-inputpart-row-graytext"
          :class="
            formValidate.dutyLevel_id
              ? 'part-inputpart-row-normaltext'
              : ''
          "
          >{{ formValidate.dutyLevel_name }}</span
        >
        <img
          class="part-inputpart-row-right"
          :class="
            isDutyLevelShow
              ? 'part-inputpart-row-right-upArrow'
              : 'part-inputpart-row-right-downArrow'
          "
          src="../../../assets/images/triangle.png"
        />
      </div>

      <div class="part-inputpart-row">
            <div>
          <span
            :class="
              !formValidate.emergencyContactPerson ? 'notdot' : 'isdot'
            "
          >
          </span>
          <span class="part-inputpart-row-header">紧急联系人</span>
          <span class="divide">|</span>
          <input
            v-model="formValidate.emergencyContactPerson"
            placeholder="请输入紧急联系人"
          />
        </div>
      </div>

      <div
        class="part-inputpart-row"
        @click="isRelationshipShow = !isRelationshipShow"
      >
        <span :class="!formValidate.emergencyContactRelationship ? 'notdot' : 'isdot'">
        </span>
        <span class="part-inputpart-row-header">紧急联系人关系</span>
        <span class="divide">|</span>
        <span
          class="part-inputpart-row-graytext"
          :class="
            formValidate.emergencyContactRelationship
              ? 'part-inputpart-row-normaltext'
              : ''
          "
          >{{ formValidate.emergencyContactRelationship_name }}</span
        >
        <img
          class="part-inputpart-row-right"
          :class="
            isRelationshipShow
              ? 'part-inputpart-row-right-upArrow'
              : 'part-inputpart-row-right-downArrow'
          "
          src="../../../assets/images/triangle.png"
        />
      </div>

      <div class="part-inputpart-row">
            <div>
          <span
            :class="
              !formValidate.emergencyContactPhone ? 'notdot' : 'isdot'
            "
          >
          </span>
          <span class="part-inputpart-row-header">紧急联系电话</span>
          <span class="divide">|</span>
          <input
              type="tel"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
              v-model="formValidate.emergencyContactPhone"
              placeholder="请输入紧急联系人"
              @blur="checkPhone"
            />
        </div>
      </div>

       <!-- 时间下拉 -->
        <van-popup v-model="isExpectedDateShow" position="bottom">
            <van-datetime-picker type="date" title="选择年月日" v-model="currentDate" :min-date="minDate"
                @confirm="expectedDateConfirm" @cancel="isExpectedDateShow = false" />
        </van-popup>
      </div>
      

      <div class="panel">
        <p class="yijian">审批意见</p>
        <div class="text_body">
          <van-field v-model="formValidate.approvalOpinion" type="textarea" class="mes_body" />
        </div>
      </div>
      <!-- 通过 -->
      <div v-if="isPass == 1">
        <div class="sub_btn">
        <van-button
          :class="
            (formValidate.emergencyContactRelationship == '' || formValidate.dutyLevel_id == ''
            || formValidate.registeredResidenceAddress == '' || formValidate.entryTime == '' 
            || formValidate.emergencyContactPerson == '' || formValidate.emergencyContactPhone == '')
              ? 'saveButton_Disable'
              : 'saveButton_Enable'
          "
          :disabled="
            (formValidate.emergencyContactRelationship == '' || formValidate.dutyLevel_id == ''
            || formValidate.registeredResidenceAddress == '' || formValidate.entryTime == '' 
            || formValidate.emergencyContactPerson == '' || formValidate.emergencyContactPhone == '')
          "
          size="large"
          @click="save"
          >保存
        </van-button>
      </div>
      </div>
      <!-- 驳回 -->
      <div v-if="isPass == 0">
        <div class="sub_btn">
        <van-button
          :class="
            !isChoose || (!selectCleanType.dictionaryTitle)
              ? 'saveButton_Disable'
              : 'saveButton_Enable'
          "
          :disabled="
            !isChoose || (!selectCleanType.dictionaryTitle)
          "
          size="large"
          @click="save"
          >保存
        </van-button>
      </div>
      </div>
      
    </div>
    <!-- 原因 -->
    <van-popup v-model="isCleanTypeShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="refuseReasonList"
        @cancel="isCleanTypeShow = false"
        :default-index="refuseReasonIndex"
        value-key="dictionaryTitle"
        @confirm="selectCleanTypeOption"
      />
    </van-popup>
     <!-- 级别 -->
    <van-popup v-model="isDutyLevelShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="dutyLevelList"
        @cancel="isDutyLevelShow = false"
        :default-index="dutyLevelIndex"
        value-key="name"
        @confirm="selectDutyLevelOption"
      />
    </van-popup>
    <!-- 紧急联系人关系 -->
    <van-popup v-model="isRelationshipShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="relationshipList"
        @cancel="isRelationshipShow = false"
        :default-index="relationshipIndex"
        value-key="dictionaryTitle"
        @confirm="selectRelationshipOption"
      />
    </van-popup>
  </div>
</template>
<script>
import BScroll from "better-scroll";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {
  NavBar,
  Tab,
  Tabs,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Tag,
  Button,
  Popup,
  Field,
  Divider,
  Image as VanImage,
  ImagePreview,
  Rate,
  DatetimePicker,
  Picker,
  Toast
} from "vant";
import {
  getQueryBaseData,
  resumeApprovalRefuse,
  resumeApprovalAgree,
  browseInterpolateApprovalDetail,
  queyDutyLevel,
  queryEmergencyContact
} from "../../../getData/getData";
import { getStaffId, responseUtil, globaluserId } from "../../../libs/rongxunUtil";
import util from '../../contract/util'
export default {
    filters: {
            formatDateTime(value) {
                if (null == value || '' == value) return '请选择年月日'
                let date = new Date(value);
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? "0" + MM : MM;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                return y + '-' + MM + '-' + d
            }
        },
  data() {
    return {
      refuseReasonIndex: "",
      dutyLevelIndex: "",
      relationshipIndex: "",
      //头部标题
      navTitle: "新人试岗审批",
      // 服务人员评价显示隐藏
      istype: 1,
      show: false,
      selectCleanType: "请选择",
      isCleanTypeShow: false,
      isCleanTypeSelect: false,
      refuseReasonList: [],
      dutyLevelList: [],
      relationshipList: [],
      isDutyLevelShow: false,
      isRelationshipShow: false,
      isChoose: true,
      isPass: 1,
      refuseId: "",
      //   auditOrCheck: false,
      staffDetail: [{ approvalOpinion: "", dictionaryTitle: "" }], //提报详情
      baseForm: {},
      formValidate: {
        // approveResult: 0,
        refuseReason_id: "",
        approvalOpinion: "",

        registeredResidenceAddress: "",
        entryTime: "",
        dutyLevel_id: "",
        // accountinfo_id: "",
        emergencyContactPerson: "",
        emergencyContactPhone: "",
        emergencyContactRelationship: "",
      },
      isExpectedDateShow: false,
      currentDate: new Date(),
      routeData: {},
      minDate: new Date(),
    };
  },
  name: "ReviewOfDecorationScheme",
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Divider.name]: Divider,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [DatetimePicker.name]: DatetimePicker,
    [Rate.name]: Rate,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
    rxNavBar,
  },

  activated() {
    //清除页面缓存
    this.isPass = 1;
    this.istype = 1;
    this.selectCleanType = ""
    this.show = false;
    this.clear();

    this.routeData = this.$route.query;
    // if (this.$route.query.approvalStatus) {
    //     this.auditOrCheck = true;
      this.navTitle = "新人试岗审批";
    // }
    this.getDetail();
    this.getRefuseReasonList();
    // this.getDutyLevelList();
    this.getRelationshipList();
  },
  methods: {
    checkPhone() {
      var reg =
          /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if ("" != this.formValidate.emergencyContactPhone) {
        if (!reg.test(this.formValidate.emergencyContactPhone)) {
          Toast("手机号格式错误");
          this.formValidate.emergencyContactPhone = "";
        }
      }
    },
    expectedDateConfirm(value){
        var date = new Date(value)
        this.formValidate.entryTime = date
        this.isExpectedDateShow = false
    },
    // 紧急联系人关系选择
    selectRelationshipOption(item, index) {
        console.log(item)
      this.formValidate.emergencyContactRelationship = item.id;
      this.formValidate.emergencyContactRelationship_name = item.dictionaryTitle;
      this.isRelationshipShow = false;
    },
    // 级别选择
    selectDutyLevelOption(item, index) {
      this.formValidate.dutyLevel_id = item.id;
      this.formValidate.dutyLevel_name = item.name;
      this.isDutyLevelShow = false;
    },
    // 拒绝原因
    selectCleanTypeOption(item, index) {
      this.selectCleanType = item;
      this.isCleanTypeShow = false;
      this.isCleanTypeSelect = true;
    this.formValidate.refuseReason_id = item.id;
    },
    multipleChoice(isPass) {
      if (isPass == 1) {
        this.istype = 1;
        this.selectCleanType = ""
        this.show = false;
      } else {
        this.istype = 0;
        this.clear();
        this.show = true;
      }
      //审核结果
      this.isChoose = true;
      this.isCleanTypeSelect = false;
      //this.grade= 0;
      this.isPass = isPass;
    },
    clear() {
       this.formValidate = {
        // approveResult: 0,
        refuseReason_id: "",
        approvalOpinion: "",

        registeredResidenceAddress: "",
        entryTime: "",
        dutyLevel_id: "",
        // accountinfo_id: "",
        emergencyContactPerson: "",
        emergencyContactPhone: "",
        emergencyContactRelationship: "",
      }

    },
    //保存
    save() {
      var that = this;
    //   var data = {};
      //通过的接口
      that.formValidate.user_id = globaluserId();
      that.formValidate.id = that.routeData.id;
      that.formValidate.approval_id = that.routeData.approval_id;
      that.formValidate.staff_id = getStaffId();

      if (that.istype == "1") {
        that.Agree();
      }
      //拒绝的接口
      if (that.istype == "0") {
        that.refuse();
      }
    },
    Agree() {
      let that = this;
      let date = that.formValidate
      date.entryTime = new Date(new Date(util.dateToString(date.entryTime)).getTime()-8*60*60*1000)
      date.channel = "1";
      console.log(date)
      resumeApprovalAgree(date).then(res => {
        console.log(res)
        if(res.data.code == "0") {
            that.leftReturn();
        } else {
            responseUtil.alertMsg(that, res.data.msg);
        }
      })
    },
    refuse() {
      let that = this;
      let date = that.formValidate
      console.log(date)
      resumeApprovalRefuse(date).then(res => {
        if(res.data.code == "0") {
            that.leftReturn();
        } else {
            responseUtil.alertMsg(that, res.data.msg);
        }
      })
    },
    //提报详情
    getDetail() {
      let that = this;
      let initData = {};
      initData.id = that.routeData.id;
      browseInterpolateApprovalDetail(initData).then(res => {
        that.baseForm = res.data.data.data
        that.baseForm.seniorStaffName = that.routeData.seniorStaffName
        that.getDutyLevelList();
      })
    },
    getRefuseReasonList() {
      let that = this;
      let initData = {};
      initData.user_id = globaluserId()
      initData.dbName = ['interpolateRefuseReason']
      initData.fdName = []
      getQueryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.refuseReasonList = response.data.data.interpolateRefuseReason;
        });
      });
    },
    getDutyLevelList() {
      let that = this;
      let initData = {};
      initData.user_id = globaluserId()
      initData.dutyId = that.baseForm.duty_id
      queyDutyLevel(initData).then(response => {
        responseUtil.dealResponse(that, response, () => {
          that.dutyLevelList = response.data.data.data;
        });
      }
      );
    },
    getRelationshipList() {
      let that = this;
      let initData = {};
      queryEmergencyContact(initData).then(response => {
        responseUtil.dealResponse(that, response, () => {
          that.relationshipList = response.data.data.data;
        });
      }
      );
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
@redius: 8px;
@border: 1px solid rgb(244, 244, 244);

.part-inputpart {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}
.part-inputpart-dropDownList {
  height: 300px;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 99.5%;
  position: absolute;
  z-index: 99;
  margin: 4px 0 0 -19px;
}
.diamod {
  width: 8px;
  height: 8px;
  background-color: #3b98ff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 17px 7px 0 18px;
  display: inline-block;
}

.t_title {
  background-color: white;
  color: #33adff;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  height: 20px;
}
.approve-opinion {
  margin-top: 10px;
}
.potential {
  width: 375px;
  background-color: rgb(250, 250, 250);
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
  color: #252525;

  .sub_btn {
    margin: 30px 0 70px;

    .sub_button {
      border-radius: @redius;
      color: rgb(255, 255, 255);
      border: none;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(
        left,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );

      &.not_choose {
        background: none;
        background-color: rgba(184, 184, 184, 0.2);
      }
    }
  }

  p {
    margin: 0px;
  }

  .not_choose {
    background: none;
    background-color: rgba(184, 184, 184, 0.2);
  }

  .application_time {
    text-align: right;
    width: 345px;
    font-size: 12px;
    color: rgb(136, 136, 136);
    padding: 5px 0 5px;
  }

  .subtitle {
    padding: 5px 0px;
    color: rgb(244, 99, 76);
    margin-top: 15px;
    font-weight: 700;
    font-size: 12px;
  }
  .overDiv {
    height: 23px;
    /*line-height: 10px;*/
    /*border: 1px solid red;*/
    display: flex;
    align-items: center;
    /*justify-content: center;*/
  }
  /*实际结束更改*/
  .overDiv > div {
    font-weight: bolder;
  }
  .overDiv > span {
    font-size: 13px;
    color: rgb(110, 110, 110);
  }

  .calendar_icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../../../assets/images/calendar.png) no-repeat center center;
    background-size: cover;
    margin-left: 10px;
    background-position-y: -1px;
  }

  /*保存按钮不可点击样式*/
  .saveButton_Disable {
    /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
    background-color: rgba(184, 184, 184, 0.2);
    color: white;
    width: 345px;
    height: 50px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1.22rem;
    margin-left: 0;
    text-align: center;
  }
  /*保存按钮可点击样式*/
  .saveButton_Enable {
    background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat
      scroll 0% 0%;
    color: white;
    width: 345px;
    height: 50px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1.22rem;
    text-align: center;
  }
  .dropDownList {
    width: 100%;
    position: absolute;
    z-index: 99;
    margin-top: 50px;
  }
  /*下拉菜单选项样式*/
  .part-inputpart-dropDownList-option {
    text-align: center;
    line-height: 35px;
    background-color: #f5f5f5;
    font-size: 12px;
    border-bottom: 1px white solid;
    color: black;
    float: left;
    width: 345px;
    height: 35px;
    /*position: fixed;*/
    /*z-index: 2001;*/
    /*margin-top: 300px;*/
    /*margin-left: 15px;*/
  }
  .divide {
    font-size: 14px;
    color: #efefef;
    margin: 0 7px 0 10px;
  }
  .part-inputpart-row-header {
    font-weight: bold;
    font-size: 14px;
    width: 90px;
    color: black;
  }
  .part-inputpart-row-graytext {
    color: #d9d9d9;
    width: 90%;
    font-size: 14px;
  }
  .part-inputpart-row-redtext {
        width: 51%;
        color: #ff5d3b;
    }
  .part-inputpart-row {
    width: 345px;
    float: left;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    height: 50px;
    border-radius: 8px;
    margin: 0 5px 15px 0;
  }
  .part-inputpart-row input {
    border: 0;
    /*width: 100%;*/
    flex: auto;
    font-size: 14px;
  }

  .part-inputpart-row input::-webkit-input-placeholder {
    color: #d8d8d8;
  }
  .notdot {
    display: inline-block;
    padding: 3.5px;
    border-radius: 50%;
    background-color: rgb(136, 136, 136);
    vertical-align: middle;
    margin-right: 13px;
    margin-left: 15px;
  }
  .isdot {
    display: inline-block;
    width: 8px;
    height: 6px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 13px;
    background-color: rgb(251, 91, 64);
    margin-left: 15px;
  }
  .optionSelectedColor {
    color: #ff5d3b;
  }
  .part-inputpart-dropDownList-option-selected {
    color: #ff5d3b;
  }
  .part-inputpart-row-normaltext {
    color: black;
  }
  .part-inputpart-row-right {
        float: right;
        margin-right: 20px;
        color: rgba(199, 199, 199, 1)
    }
    .part-inputpart-row-right-timepick {
        width: 20px;
    }
  .part-inputpart-row-right-downArrow {
    width: 9px;
    height: 9px;
    margin-right: 15px;
  }
  /*选择三角图标的向上向下样式*/
  .part-inputpart-row-right-upArrow {
    width: 9px;
    height: 9px;
    /*transform: rotateX(180deg);*/
    margin-right: 15px;
  }

  .panel {
    width: 345px;
    background-color: rgb(255, 255, 255);
    border-radius: @redius;
    position: relative;
    font-size: 14px;
    text-align: left;
    overflow: hidden;

    &.withMargin {
      margin: 16px 0;
    }

    & > p {
      padding: 15px 15px 0px;
      font-weight: bolder;
      /*color: #252525;*/
    }

    & > p > span {
      font-weight: normal;
      color: rgb(190, 190, 190);
    }

    .state {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      font-weight: bold;
      height: 32px;
      line-height: 32px;
      width: 60px;
      display: block;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(
        left,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to right,
        rgba(255, 194, 116, 1),
        rgba(255, 93, 59, 1)
      );
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: rgb(255, 255, 255);
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;
      font-family: sans-serif;
      padding: 0 10px;
    }

    .title {
      width: 315px;
      padding: 0 15px;
      font-weight: bolder;
      color: rgb(0, 0, 0);
      font-size: 16px;
      padding-top: 20px;

      span {
        font-size: 12px;
        font-weight: normal;
        color: rgba(153, 153, 153, 1);
      }
    }
    .allprice {
      padding-bottom: 4px;
    }
    /*审核结果,服务人员 审批意见*/
    .shenhe,
    .fuwu,
    .yijian {
      font-size: 15px;
    }
    .describe {
      padding-bottom: 15px;
      padding-top: 10px;
      color: rgb(82, 82, 82);
      font-weight: normal;
    }

    .info {
      padding: 15px;
      line-height: 16px;

      &.noPadding {
        padding: 0 15px;
      }

      p {
        margin: 7px 0;
        font-weight: bolder;

        span {
          font-size: 13px;
          font-weight: normal;
          color: rgb(110, 110, 110);
        }
      }

      .floatR {
        float: right;
      }

      .total_color {
        color: rgba(255, 93, 59, 1);
      }
    }

    .text_body {
      padding: 15px;

      .mes_body {
        border-radius: @redius;
        background-color: rgb(250, 250, 250);
      }
    }
  }

  // 单选框
  .radio_body {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;

    .dot {
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: rgb(136, 136, 136);
      vertical-align: middle;
      margin-right: 10px;
      margin-bottom: 6px;

      &.is_dot {
        background-color: rgb(251, 91, 64);
      }
    }

    .line {
      width: 2px;
      height: 12px;
      background-color: rgb(250, 250, 250);
    }

    .choose {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;

      .choose_btn {
        padding: 5px;
        margin: 10px;
        color: rgb(136, 136, 136);
        cursor: pointer;

        &.pass {
          color: rgb(251, 91, 64);
          font-weight: bolder;
        }

        &.reject {
          color: red;
          font-weight: bolder;
        }
      }
    }
  }

  // 循环表单
  .t_table {
    .t_header {
      text-align: left;
      color: rgb(136, 136, 136);
      /*font-weight: bolder;*/
      font-size: 10px;
      height: 20px;
      line-height: 21px;
      background-color: rgba(243, 249, 255, 1);
    }

    .t_title {
      //background-color: rgb(244, 249, 255);
      color: #33adff;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      height: 20px;
    }

    .t_td {
      text-align: left;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
    }
    .t_td_1 {
      text-align: left;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      padding-left: 10px;
    }
  }

  // 图片墙
  .swiper_show {
    width: 325px;
    position: relative;
    margin: 15px 10px;
    height: 75px;

    .content {
      position: absolute;
      display: flex;
      justify-content: space-around;
      left: 0px;

      .imgWrap {
        margin-right: 10px;
      }
    }

    .num {
      position: absolute;
      left: 4px;
      top: 6px;
      font-size: 10px;
      background-color: rgb(0, 0, 0);
      opacity: 0.5;
      color: rgb(255, 255, 255);
      padding: 2px 6px;
      display: inline-block;
      border-radius: 10px;
      font-weight: bolder;
      line-height: 10px;
    }
  }
  .content-divide {
    font-size: 12px;
    color: #efefef;
    margin: 0 10px;
  }
}
.part-inputpart-row-enablenon {
        margin-left: 26px;
    }
.part-inputpart-row-header {
        font-weight: 900;
    }    
</style>
